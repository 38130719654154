@font-face {
  font-family: 'GT Pressura Mono';
  src: url('/fonts/GTPressuraMono-Bold.woff2') format('woff2'), url('/fonts/GTPressuraMono-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'New Rail Alphabet';
  src: url('/fonts/NewRailAlphabetWEB-Light.woff2') format('woff2'), url('/fonts/NewRailAlphabetWEB-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Encode Sans Condensed';
  src: url('/fonts/encode-sans-expanded-v4-latin-regular.woff2') format('woff2'), url('/fonts/encode-sans-expanded-v4-latin-regular.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Encode Sans Condensed';
  src: url('/fonts/Helvetica.ttf') format('ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Verdana';
  src: url('/fonts/Verdana.woff2') format('woff2'), url('/fonts/Verdana.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}


@import url('https://fonts.googleapis.com/css2?family=Jost:wght@100&display=swap');
body {
  margin: 0;
  font-family: 'New Rail Alphabet', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Box sizing rules */

*, *::before, *::after {
  box-sizing: border-box;
}

/* Remove default padding */

ul, ol {
  padding: 0;
}

/* Remove default margin */

body, h1, h2, h3, h4, p, ul, ol, li, figure, figcaption, blockquote, dl, dd {
  margin: 0;
}

/* Set core body defaults */

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements */

ul, ol {
  list-style: none;
}

/* A elements get default styles */

a {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */

img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */

article>*+* {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */

input, button, textarea, select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}